import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout'

import SubHero from '../components/SubHero'
import Helmet from 'react-helmet'

import ServicesDisplay from '../components/ServicesDisplay'
import TechStack from '../components/TechStack'

class IndividualServices extends React.Component {
  static propTypes = {
    isStatic: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.node,
  }

  render() {
    return (
      <Layout>
        <Helmet
          title="Individual Services | NEXTGEN Code Company"
          meta={[
            {
              name: 'description',
              content:
                "Website's, SEO, search engine optimization, mobile app's, web applications, UI UX design, and more serving the Dallas, Lubbock and Corpus Christi Texas areas.",
            },
          ]}
        />
        <SubHero
          defaultBackText={'Services'}
          defaultBackURL={'/services'}
          title="Things We Can Do"
          subtitle=""
        />

        <section className="section">
          <div className="container">
            <h1>Hire us for a specific task</h1>
            <p>
              If you already know what you want, perfect! We can help. Our
              services <b>are not limited</b> to this list. These are just our
              most common ones.
            </p>
            <ServicesDisplay />
          </div>
        </section>

        <TechStack />

        <section className="section">
          <div className="container">
            <h3>To find out more about these, reach out to us.</h3>
            <h1>Don't be shy!</h1>
          </div>
        </section>
      </Layout>
    )
  }
}

export default IndividualServices
