import React from 'react'
import {
  FaReact,
  FaNodeJs,
  FaAndroid,
  FaAws,
  FaHtml5,
  FaCss3,
  FaAngular,
  FaJava,
  FaPhp,
  FaLaravel,
  FaApple,
} from 'react-icons/fa'
import { DiMysql, DiJqueryLogo, DiPostgresql, DiMongodb } from 'react-icons/di'
import { IoLogoJavascript, IoLogoIonic } from 'react-icons/io'
import elasticSearch from '../assets/images/elasticsearch.png'
import firebase from '../assets/images/firebase.png'
import oracle from '../assets/images/oracle.png'
import google_cloud from '../assets/images/google_cloud.png'
import microsoft_azure from '../assets/images/microsoft_azure.png'
import Icon from './Icon'

const color =
  'invert(93%) sepia(95%) saturate(2%) hue-rotate(297deg) brightness(104%) contrast(97%)'

export const frontEnd = [
  {
    icon: <FaReact size={80} />,
    title: 'React JS',
  },
  {
    icon: <FaAngular size={80} />,
    title: 'Angular JS',
  },
  {
    icon: <FaHtml5 size={80} />,
    title: 'HTML5',
  },
  {
    icon: <FaCss3 size={80} />,
    title: 'CSS3',
  },
  {
    icon: <IoLogoJavascript size={80} />,
    title: 'JavaScript',
  },
  {
    icon: <DiJqueryLogo size={80} />,
    title: 'jQuery',
  },
]
export const backEnd = [
  {
    icon: <FaNodeJs size={80} />,
    title: 'Node JS',
  },
  {
    icon: <FaJava size={80} />,
    title: 'Java',
  },
  {
    icon: <FaPhp size={80} />,
    title: 'PHP',
  },
  {
    icon: <FaLaravel size={80} />,
    title: 'Laravel',
  },
]
export const mobile = [
  {
    icon: <FaReact size={80} />,
    title: 'React Native',
  },
  {
    icon: <IoLogoIonic size={80} />,
    title: 'Ionic',
  },
  {
    icon: <FaApple size={80} />,
    title: 'iOS',
  },
  {
    icon: <FaAndroid size={80} />,
    title: 'Android',
  },
]
export const database = [
  {
    icon: <DiMysql size={80} />,
    title: 'MySQL',
  },
  {
    icon: <Icon source={oracle} filter={color} size={80} />,
    title: 'Oracle',
  },
  {
    icon: <DiPostgresql size={80} />,
    title: 'PostgreSQL',
  },
  {
    icon: <DiMongodb size={80} />,
    title: 'MongoDB',
  },
  {
    icon: <Icon source={elasticSearch} filter={color} size={80} />,
    title: 'Elasticsearch',
  },
  {
    icon: <Icon source={firebase} filter={color} size={80} />,
    title: 'Firebase',
  },
]
export const cloud = [
  {
    icon: <FaAws size={80} />,
    title: 'Amazon Web Services',
  },
  {
    icon: <Icon source={google_cloud} filter={color} size={80} />,
    title: 'Google Cloud Platform',
  },
  {
    icon: <Icon source={microsoft_azure} filter={color} size={80} />,
    title: 'Microsoft Azure',
  },
]

export const TechCard = props => {
  return (
    <div className="ng-techCard-container">
      <div className="ng-techCard-icon">{props.icon}</div>
      <div className="ng-techCard-title">
        <p>{props.title}</p>
      </div>
    </div>
  )
}
