import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import {
  frontEnd,
  backEnd,
  mobile,
  database,
  cloud,
  TechCard,
} from './TechCard'

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const a11yProps = index => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const TechStackPreview = () => {
  const theme = useTheme()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  return (
    <div className="ng-techStackPreview-container">
      <AppBar
        position="static"
        color="transparent"
        className="ng-techStackPreview-tabBar"
      >
        <Tabs
          className="ng-techStackPreview-tabs"
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          variant="fullWidth"
        >
          <Tab label="Frontend" {...a11yProps(0)} />
          <Tab label="Backend" {...a11yProps(1)} />
          <Tab label="Mobile" {...a11yProps(2)} />
          <Tab label="Database" {...a11yProps(3)} />
          <Tab label="The Cloud" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div className="ng-techStackPreview-tab-panel">
            {frontEnd.map((item, index) => (
              <TechCard key={index} icon={item.icon} title={item.title} />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className="ng-techStackPreview-tab-panel">
            {backEnd.map((item, index) => (
              <TechCard key={index} icon={item.icon} title={item.title} />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div className="ng-techStackPreview-tab-panel">
            {mobile.map((item, index) => (
              <TechCard key={index} icon={item.icon} title={item.title} />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <div className="ng-techStackPreview-tab-panel">
            {database.map((item, index) => (
              <TechCard key={index} icon={item.icon} title={item.title} />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          <div className="ng-techStackPreview-tab-panel">
            {cloud.map((item, index) => (
              <TechCard key={index} icon={item.icon} title={item.title} />
            ))}
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  )
}

export default TechStackPreview
