import React from 'react'
import TechStackPreview from './TechStackPreview'

const TechStack = () => {
  return (
    <section className="section ng-techStack">
      <div className="container ng-techStack-container">
        <h3>What We Use</h3>
        <TechStackPreview />
        <p>
          Our goal is to provide the best experience possible by using the most
          up to date technologies
        </p>
      </div>
    </section>
  )
}

export default TechStack
